import { UrlSegment, UrlMatchResult } from '@angular/router';

export function matcherFn(url: UrlSegment[], index: number) {
  const urlPath = url.join('/');
  const regex = /^([a-zA-Z0-9]+)(-([a-zA-Z0-9]+))*$/;
  if (regex.test(urlPath)) {
    // const segments: UrlSegment[] = [];
    const pathSegments = urlPath.split('-');
    let return_path = '';
    if (index == 2) return_path = `${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    if (index == 3) return_path = `${pathSegments[pathSegments.length - 3]}-${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    return return_path
  }
  return null
}

export function countryUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
  if (matcherFn(url, 2) == 'visa-online') return { consumed: url }
  return null;
}

// export function categoryUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
//   if (matcherFn(url, 3) == 'visa-online-india') return { consumed: url }
//   return null;
// }

export function productUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
  if (matcherFn(url, 3) == 'visa-apply-online') return { consumed: url }
  return null;
}
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { GetDataService } from 'src/app/core/services/get-data.service';

declare var webengage: any; // Declare the webengage variable

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  applicationNo!: string | null;
  appConstants = new AppConstants();

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    private getDataService: GetDataService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.webengageTriggerSuccess();
    this.getDataFromParams();
    this.sendLink();
  }
  goToMyBooking() {
    this.router.navigate([this.appConstants.myProfile]);
  }
  getDataFromParams() {
    this.applicationNo =
      this.activeRoute.snapshot.queryParamMap.get('application_no');
  }

  webengageTriggerSuccess() {
    webengage.track('Payment Sucess', {
      'Payment Status': 'Success',
    });
  }

  sendLink() {
    if (isPlatformBrowser(this.platformId)) {
      const url = localStorage.getItem('fiMoneyUrlLink')
        ? localStorage.getItem('fiMoneyUrlLink')
        : '';
      const data = {
        fi_link: url,
      };
      this.getDataService.sendFiMoneyLink(data).subscribe({
        next: (res) => {},
      });
    }
  }
}

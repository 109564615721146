<div class="header_dynamic_mgn_set"></div>
<div class="primary_background header-inverse">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- overflow-hidden -->

                <div class="header justify_space_between w-100 ">
                    <!--^ (scroll)="onWindowScroll();" -->
                    <div class="display_flex justify_space_between flex_align_center width_perc_100_mbl">
                        <a [routerLink]="appConstants.home" class="text-reset text-decoration-none"
                            (click)="homeTrigger()">
                            <div class="logo_section pointer">
                                <img src="/assets/images/logo.webp" class="logo_img_width" alt="STP">
                            </div>
                        </a>

                        <div class="d-flex align-items-center">
                            <div class="d-none pointer pe-3">
                                <div class="input-group">
                                    <ng-select *ngIf="toggleSearch" class="width_ngselect" [multiple]="false"
                                        [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                        [disabled]="!countryList" [placeholder]="'Search Country'">
                                        <ng-option class="white_bg" *ngFor="let country of countryList"
                                            [value]="country.countryUrl" [disabled]="false">
                                            {{country.name}}
                                        </ng-option>
                                    </ng-select>
                                    <span class="input-group-append" (click)="search();"
                                        [ngClass]="{'inside': toggleSearch}">
                                        <div class="white_color serach_icon">
                                            <span class="material-icons-outlined">
                                                search
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="menu_icon white_color mt-2" (click)="displayMenu()">
                                <span class="material-icons-outlined">
                                    menu
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="menu_container menu_width_none md_flex_align_center" id="menu">
                        <div class="overlay" (click)="closeMenu()"></div>
                        <div class="menu_body" *ngIf="showMenu">

                            <div class="menu_content">
                                <div class="d-xl-none d-block padding_lr_15 pointer margin_lr_3 text-end margin_top_minus"
                                    (click)="closeMenu()">
                                    <span class="material-icons-outlined">
                                        close
                                    </span>
                                </div>
                                <div class="d-none padding_lr_15 pointer margin_lr_3">
                                    <div class="input-group">
                                        <ng-select class="width_ngselect" [multiple]="false"
                                            [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                            [disabled]="!countryList" [placeholder]="'Search Country'">
                                            <ng-option class="white_bg" *ngFor="let country of countryList"
                                                [value]="country.countryUrl" [disabled]="false">
                                                {{country.name}}
                                            </ng-option>
                                        </ng-select>
                                        <span class="input-group-append">
                                            <div class="serach_icon" (click)="searchMob();">
                                                <span class="material-icons-outlined">
                                                    search
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="padding_lr_15 pointer margin_lr_3">VISA</div> -->
                                <!-- <div class=" padding_lr_15 pointer margin_lr_3" [routerLink]="[appConstants.newsUrl]">
                                    NEWS
                                </div>
                                <div class=" padding_lr_15 pointer margin_lr_3" [routerLink]="[appConstants.blogUrl]">
                                    BLOGS
                                </div> -->
                                <!-- <div class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase"
                                    (click)="tripPlannerServc()">
                                    TRIP PLANNER
                                </div>
                                <div class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase"
                                    (click)="airportSrvc()">
                                    AIRPORT SERVICES
                                </div> -->
                                <a [routerLink]="appConstants.aboutUsUrl" class="text-reset text-decoration-none"
                                    (click)="aboutUsTrigger()">
                                    <div class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium">
                                        ABOUT US
                                    </div>
                                </a>

                                <!-- <div class="padding_lr_15 pointer margin_lr_3" [routerLink]="[appConstants.faqUrl]">FAQs</div> -->
                                <a href="https://api.whatsapp.com/send/?phone=919076133777&text=Hello&type=phone_number&app_absent=0"
                                    class="text-reset text-decoration-none" (click)="contactUsTrigger()"
                                    target="_blank">
                                    <div
                                        class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase d-flex align-items-center">
                                        <img class="whatsapp-icon me-1" src="assets/images/social/WhatsApp_icon.webp">
                                        Chat with us
                                    </div>
                                </a>
                                <div class="d-none padding_lr_15 pointer margin_lr_3">
                                    <div class="input-group">
                                        <ng-select *ngIf="toggleSearch" class="width_ngselect" [multiple]="false"
                                            [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                            [disabled]="!countryList" [placeholder]="'Search Country'">
                                            <ng-option class="white_bg" *ngFor="let country of countryList"
                                                [value]="country.countryUrl" [disabled]="false">
                                                {{country.name}}
                                            </ng-option>
                                        </ng-select>
                                        <span class="input-group-append" (click)="search();"
                                            [ngClass]="{'inside': toggleSearch}">
                                            <div class="serach_icon">
                                                <span class="material-icons-outlined">
                                                    search
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <a [routerLink]="appConstants.signIn" class="text-reset text-decoration-none"
                                    *ngIf="!userName">
                                    <!-- (click)="goToLogin()" -->
                                    <div
                                        class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase">
                                        LOGIN
                                    </div>
                                </a>

                                <div class=" padding_lr_15 pointer margin_lr_3 d-lg-block d-xl-block d-sm-none d-md-none d-none"
                                    *ngIf="userName || token">
                                    <!-- <span class="material-icons-outlined">
                                    person_outline
                                </span> -->
                                    <div ngbDropdown class="d-inline-block">
                                        <button type="button" class="btn log-btn" id="dropdownBasic1" ngbDropdownToggle>
                                            <span class="material-icons-outlined">
                                                person_outline
                                            </span>
                                            <span class="ms-3 me-2" style="vertical-align: super;">{{userName}}</span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" placement="bottom-left">
                                            <!-- <button ngbDropdownItem [routerLink]="[myVisaRouting]"
                                                queryParamsHandling="preserve"> ngbDropdownToggle
                                                My Visa
                                            </button> -->
                                            <button *ngIf="isLogin" ngbDropdownItem [routerLink]="[myProfileUrl]"
                                                queryParamsHandling="preserve">
                                                My Profile
                                            </button>
                                            <!-- <button ngbDropdownItem class="danger">Logout</button> -->
                                            <button ngbDropdownItem class="danger" (click)="goToLogin()"
                                                *ngIf="!isLogin && showLoginBtn">
                                                Login/Signup
                                            </button>
                                            <button ngbDropdownItem class="danger" (click)="Logout()"
                                                *ngIf="isLogin">Logout</button>
                                        </div>
                                    </div>
                                </div>
                                <div class=" padding_lr_15 pointer margin_lr_3 d-lg-none d-xl-none" *ngIf="userName">
                                    <!-- <span class="material-icons-outlined">
                                    person_outline
                                    </span> -->
                                    <div ngbDropdown class="d-inline-block">
                                        <button type="button" class="btn log-btn" id="dropdownBasic1">
                                            <span class="material-icons-outlined">
                                                person_outline
                                            </span>
                                            <span class="ms-3 me-2" style="vertical-align: super;">{{userName}}</span>
                                        </button>
                                    </div>
                                </div>

                                <div class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase d-lg-none d-xl-none"
                                    [routerLink]="[myProfileUrl]" queryParamsHandling="preserve" *ngIf="userName">
                                    MY PROFILE</div>
                                <div class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase d-lg-none d-xl-none"
                                    [routerLink]="[appConstants.contactUsUrl]" (click)="Logout()" *ngIf="userName">
                                    LOGOUT</div>
                                <!-- <div class=" padding_lr_15 pointer margin_lr_3">
                                    <button type="button"
                                        class="btn orange_bg_color padding_consultant rounded-0 converted_font_16 black_color text-uppercase"
                                        [routerLink]="[consultantServ]" (click)="visaProWebEngageTrig()">
                                        VISA PRO
                                    </button>
                                </div> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="menu_modal">
    <ng-template #content let-modal>
        <div class="modal-body">
            <p>Please select the country to proceed furthure</p>
            <button type="button" class="btn btn-primary read-more converted_font_16 white_color" aria-label="Close"
                (click)="modal.dismiss()">
                Select The Country
            </button>
        </div>
    </ng-template>
</div>